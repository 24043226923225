import { useState } from 'react';
import { Typography } from '@mui/material';
import { MainContentPanel } from '../components/MainContentPanel';
import { OrderSearchableTable } from './overview/OrderSearchableTable';

interface Props {
  licenseId: number;
}

export function Orders({ licenseId }: Props) {
  const [showIncomplete, setShowIncomplete] = useState(false);

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Ordre
      </Typography>
      <OrderSearchableTable showIncomplete={showIncomplete} setShowIncomplete={setShowIncomplete} licenseId={licenseId} />
    </MainContentPanel>
  );
}
