import { useState } from 'react';
import { Typography } from '@mui/material';
import { OrderSearchableTable } from './OrderSearchableTable';
import { GET_ORDERS } from './Orders.graphql';
import { MainContentPanel } from '../../components/MainContentPanel';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Order } from '../../interfaces';
import { useConfig } from '../../components/Configuration';
import { useQuery } from '@apollo/client';

export default function Overview() {
  const [showIncomplete, setShowIncomplete] = useState(false);

  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Ordre
      </Typography>
      <OrderSearchableTable showIncomplete={showIncomplete} setShowIncomplete={setShowIncomplete} />
    </MainContentPanel>
  );
}
